
export default {
    name: 'FormStep',
    data() {
        return {
            // 选中步骤
            active: 0,
            // 表单一数据
            form1: {
                tracking_numbers: [],
                claimType: 'LOSS',
                lossType: 'NOTLOCATED',
                additionalComments: ''
            },
            // 表单一验证规则
            rules1: {
                tracking_numbers: [{
                    required: true,
                    message: this.$t('share.require'),
                    type: 'array',
                    trigger: 'blur'
                }],
                claimType: [{
                    required: true,
                    message: this.$t('share.require'),
                    type: 'string',
                    trigger: 'blur'
                }],
                lossType: [{
                    required: true,
                    message: this.$t('share.require'),
                    type: 'string',
                    trigger: 'blur'
                }]
            },
            // 步骤一提交状态
            loading1: false,
            // 表单二数据
            form2: {
                numberOfPackages: '',
                detailedMerchandiseDesc: '',
                noOfItems: '',
                amount: '',
                amountCurrency: ''
            },
            // 表单二验证规则
            rules2: {
                numberOfPackages: [{
                    required: true,
                    message: this.$t('share.require'),
                    type: 'string',
                    trigger: 'blur'
                }],
                detailedMerchandiseDesc: [{
                    required: true,
                    message: this.$t('share.require'),
                    type: 'string',
                    trigger: 'blur'
                }],
                noOfItems: [{
                    required: true,
                    message: this.$t('share.require'),
                    type: 'string',
                    trigger: 'blur'
                }],
                amount: [{
                    required: true,
                    message: this.$t('share.require'),
                    type: 'string',
                    trigger: 'blur'
                }],
                amountCurrency: [{
                    required: true,
                    message: this.$t('share.require'),
                    type: 'string',
                    trigger: 'blur'
                }]
            },
            // 步骤二提交状态
            loading2: false,
            // 单选框样式
            radio_style: {
                display: 'block',
                height: '30px',
                lineHeight: '30px',
            },
            // 跟踪号列表
            tracking_numbers: []
        };
    },
    mounted() {
        this.load_tracking_numbers();
    },
    methods: {
        load_tracking_numbers() {
            this.$http.get(`/user/order/get_tracking_numbers/${this.$route.query.id}`).then(res => {
                if (res.data.code === 0) {
                    this.tracking_numbers = res.data.data.map(a => ({
                        value: a
                    }));
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
        /* 步骤一提交 */
        submit1() {
            this.$refs.form1.validate().then(() => {
                this.loading1 = true;
                setTimeout(() => {
                    this.loading1 = false;
                    this.active = 1;
                }, 300);
            }).catch(() => {});
        },
        /* 步骤二提交 */
        submit2() {
            this.$refs.form2.validate().then(() => {
                this.loading2 = true;
                setTimeout(() => {
                    this.loading2 = false;
                    this.active = 2;
                }, 300);
            }).catch(() => {});
        }
    }
}
